<template>
  <div>
    <click-to-edit
      v-model="value"
      type="date-picker"
      :disabled="disabled"
      placeholder=""
      @input="$emit('change', value)"
    ></click-to-edit>
  </div>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import ClickToEdit from "@/components/ClickToEdit";

export default {
  name: "linear-three-targets-card-fact-view",
  mixins: [dynamicScaleComponent],
  components: {ClickToEdit},

  props: {
    disabled:{type: Boolean, default: false},
    factPropName: {type: String, default: 'fact'},
  },

  data() {
    return {
      value: null,
      scale: {},
    }
  },
  mounted() {
    this.value = this.data[this.factPropName];
  },
  computed: {

  },
  methods: {

  }
}
</script>

<style>

</style>